/**
 * It's suggested to configure the RESTful endpoints in this file
 * so that there is only one source of truth, future update of endpoints
 * could be done from here without refactoring on multiple places throughout the app
 */

// Authentication related API urls
export const VERIFY_MEETING = "meeting-scheduler/verify-meeting-url";
// export const SOCKET_URL_ENDPOINT = "https://staging-api.xpofairs.com";
export const SOCKET_URL_ENDPOINT = "https://api.xpofairs.com";
// export const SOCKET_URL_ENDPOINT = "https://35.176.158.190";
// Secrete Key
export const secretKey = "XPOSecretKey";

// variables
export const USER_TYPES = {
  EXHIBITOR: "exhibitor",
  VISITOR: "visitor",
  ORGANISER: "organiser",
};
