import close from "../../images/discussion.png";
import "./screen.css";

const ClosedMeeting = () => {
  return (
    <>
      <div className="meeting-close-div">
        <img src={close} alt="close" />
        <h2 className="meeting-close-text">
          The meeting you are attempting to join has already ended.
        </h2>
      </div>
    </>
  );
};

export default ClosedMeeting;
