import { DataService } from "../../config/dataService/dataService";
import { VERIFY_MEETING } from "../../config/api";
import { message } from "antd";

export const verifyMeeting = (token) => {
  return async (dispatch) => {
    const resp = await DataService.get(VERIFY_MEETING + "?meetingId=" + token);
    if (!resp.data.error) {
      dispatch({
        type: VERIFY_MEETING,
        payload: resp.data.data,
      });
      return resp.data.data;
    } else {
      return false;
    }
  };
};
