import { useContext, useEffect } from "react";
import { USER_TYPES } from "../../config/api";
import { SocketContext } from "../../socket/socketContext";
import { getItem, removeItem, setItem } from "../../utils/localStorageControl";

export function LeaveScreen({
  setIsMeetingLeft,
  token,
  meetingId,
  setMeetingStarted,
}) {
  //XPO_FAIRS hook
  const socket = useContext(SocketContext);

  const personRole = getItem("personRole");
  const verifiedResponse = getItem("verifiedResponse");
  const meetingStatus = getItem("meetingStatus");
  console.log("🚀 ~ file: LeaveScreen.js:17 ~ meetingStatus:", meetingStatus);

  useEffect(() => {
    if (personRole === USER_TYPES.EXHIBITOR) {
      let payload = {
        meetingId: verifiedResponse.meetingId,
        creatorId: verifiedResponse.creatorId,
        role: USER_TYPES.EXHIBITOR,
        userId: verifiedResponse?.joineeId,
      };
      console.log("endScheduledMeeting Exhibitor");
      socket.emit("endScheduledMeeting", payload);
      setItem("meetingStatus", "closed");

      removeItem("meetingResume");
    } else {
      console.log("sdkjgfjasgdjkgsdfhgjk");
      let payload = {
        meetingId: verifiedResponse.meetingId,
        creatorId: verifiedResponse.creatorId,
        role: USER_TYPES.VISITOR,
        userId: verifiedResponse?.creatorId,
      };
      console.log("endScheduledMeeting Visitor");
      setItem("meetingStatus", "closed");
      socket.emit("endScheduledMeeting", payload);
    }
  }, []);

  const handleRejoinMeeting = async () => {
    if (token && meetingId) {
      socket.emit("joinScheduledMeeting", {
        userId: verifiedResponse.creatorId,
        meetingId: verifiedResponse.meetingId,
      });
      setMeetingStarted(true);
      setItem("meetingStatus", "start");
    } else {
      setIsMeetingLeft(false);
      setMeetingStarted(false);
    }
  };
  // console.log("displayname",displa)
  const handleReturn = () => {};
  return (
    <div className="bg-gray-800 h-screen flex flex-col flex-1 items-center justify-center">
      <h1 className="text-white text-4xl">
        {personRole === USER_TYPES.EXHIBITOR
          ? "Meeting has ended!"
          : meetingStatus === "closed"
          ? "Meeting has ended!"
          : "You left the meeting!"}
      </h1>
      <h3 className="text-white text-3xl pt-9">Close this tab</h3>
      <div className="mt-12">
        {personRole !== USER_TYPES.EXHIBITOR && meetingStatus !== "closed" ? (
          <button
            className="`w-full bg-purple-350 text-white px-8 py-3 rounded-lg text-sm mr-3"
            onClick={() => {
              handleRejoinMeeting();
            }}
          >
            Rejoin the Meeting
          </button>
        ) : (
          <></>
        )}

        {/* <button
          className="`w-full bg-purple-350 text-white px-8 py-3 rounded-lg text-sm"
          style={{ cursor: "default" }}
          onClick={() => {
            handleReturn();
          }}
        >
          Close This Tab
        </button> */}
      </div>
    </div>
  );
}
