import * as React from "react";

const ChatIcon = (props) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame 3980">
        <path
          id="Vector 196"
          d="M19.1397 5.498C15.2051 0.588259 9.09992 1.43603 6.12072 2.35418C3.53002 3.15261 0.816963 5.78381 1.0097 8.73709C1.2827 12.9202 4.86703 14.7389 4.86703 14.7389C4.86703 14.7389 5.44616 15.9775 3.90269 18.0733C2.35922 20.1691 8.23198 16.8277 9.09992 15.6844C10.7393 15.6844 11.2425 15.8893 13.5465 15.4058C20.2147 14.0065 20.972 7.78442 19.1397 5.498Z"
          stroke="#454845"
          stroke-width="1.5"
        />
        <path
          id="Vector 197"
          d="M22.5653 9.5C22.5653 9.5 21.9743 17.8713 11 17.8713C12.4858 20.611 16.6279 20.9154 18.5132 20.7251C19.7795 23.1033 23.3493 24.0358 22.8185 23.1985C22.3964 22.5326 21.9462 20.6617 21.9743 19.5836C26.9719 16.3873 24.7601 10.8318 22.5653 9.5Z"
          fill={`${props.style.color}`}
        />
        <g id="Group 3">
          <circle
            id="Ellipse 281"
            cx="5.75"
            cy="8.75"
            r="1.75"
            fill={`${props.style.color}`}
          />
          <circle
            id="Ellipse 283"
            cx="15.75"
            cy="8.75"
            r="1.75"
            fill={`${props.style.color}`}
          />
          <circle
            id="Ellipse 282"
            cx="10.75"
            cy="8.75"
            r="1.75"
            fill={`${props.style.color}`}
          />
        </g>
      </g>
    </svg>
  );
};

export default ChatIcon;
