const API_BASE_URL = "https://api.xpofairs.com/api/v1";
// const API_BASE_URL = "https://staging-api.xpofairs.com/api/v1";
// const API_BASE_URL = "http://35.176.158.190/api/v1";
const VIDEOSDK_TOKEN = process.env.REACT_APP_VIDEOSDK_TOKEN;
const API_AUTH_URL = "https://api.xpofairs.com/api/v1";
// const API_AUTH_URL = "https://staging-api.xpofairs.com/api/v1";
// const API_AUTH_URL = "http://35.176.158.190/api/v1";

export const getToken = async () => {
  if (VIDEOSDK_TOKEN && API_AUTH_URL) {
    console.error(
      "Error: Provide only ONE PARAMETER - either Token or Auth API"
    );
  } else if (VIDEOSDK_TOKEN) {
    return VIDEOSDK_TOKEN;
  } else if (API_AUTH_URL) {
    const res = await fetch(`${API_AUTH_URL}/videosdk/get-token`, {
      method: "GET",
    });

    const data = await res.json();

    // console.log("token", data.data.token);
    return data.data.token;
  } else {
    console.error("Error: ", Error("Please add a token or Auth Server URL"));
  }
};

export const createMeeting = async ({ token }) => {
  // console.log("token data", token);
  const url = `${API_BASE_URL}/videosdk/create-meeting`;
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ token }),
  };

  // const { roomId } = await fetch(url, options)
  const data = await fetch(url, options)
    .then((response) => response.json())
    .catch((error) => console.error("error", error));
  // console.log("roomId", data);
  return data.data.roomId;
};

export const validateMeeting = async (roomId, token) => {
  // console.log("🚀 ~ file: api.js:45 ~ validateMeeting ~ token:", token);
  // console.log(roomId, token, "data");
  const url = `https://api.videosdk.live/v2/rooms/validate/${roomId}`;

  const options = {
    method: "GET",
    headers: { Authorization: token, "Content-Type": "application/json" },
  };

  const result = await fetch(url, options)
    .then((response) => response.json()) //result will have meeting id
    .catch((error) => console.error("error", error));

  return result ? result.roomId === roomId : false;
};
