import { VERIFY_MEETING } from "../../config/api";

const initialState = {
  verify: [],
};

const meetingSchedularReducer = (state = initialState, action) => {
  switch (action.type) {
    case VERIFY_MEETING:
      return {
        ...state,
        meetingConfrencing: action.payload,
      };

    default:
      return state;
  }
};
export default meetingSchedularReducer;
