import { SOCKET_URL_ENDPOINT } from "../config/api";
import React from "react";
import io from "socket.io-client";

const SocketContext = React.createContext();

const SocketProvider = ({ children }) => {
  const ENDPOINT = SOCKET_URL_ENDPOINT; /// Live Url ===>>> http://185.79.115.197:9500/  LocalHost Url ===>>> http://localhost:9500/
  const socket = io(ENDPOINT, { transports: ["polling"] });
  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export { SocketContext, SocketProvider };
