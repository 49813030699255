import {
  Constants,
  MeetingProvider,
  useMeeting,
} from "@videosdk.live/react-sdk";
import React, { useContext, useEffect, useState } from "react";
import { MeetingAppProvider } from "./MeetingAppContextDef";
import { JoiningScreen } from "./components/screens/JoiningScreen";
import { LeaveScreen } from "./components/screens/LeaveScreen";
import { USER_TYPES } from "./config/api";
import { MeetingContainer } from "./meeting/MeetingContainer";
import { SocketContext } from "./socket/socketContext";
import { getItem, removeItem, setItem } from "./utils/localStorageControl";
import { getToken } from "./api";

const App = () => {
  const mMeeting = useMeeting();
  const [token, setToken] = useState("");
  const [meetingId, setMeetingId] = useState("");
  const [participantName, setParticipantName] = useState("");
  const [micOn, setMicOn] = useState(true);
  const [webcamOn, setWebcamOn] = useState(true);
  const [selectedMic, setSelectedMic] = useState({ id: null });
  const [selectedWebcam, setSelectedWebcam] = useState({ id: null });
  const [selectWebcamDeviceId, setSelectWebcamDeviceId] = useState(
    selectedWebcam.id
  );
  const [meetingMode, setMeetingMode] = useState(Constants.modes.CONFERENCE);
  const [selectMicDeviceId, setSelectMicDeviceId] = useState(selectedMic.id);
  const [isMeetingStarted, setMeetingStarted] = useState(false);
  const [isMeetingLeft, setIsMeetingLeft] = useState(false);
  const [setForceClose, forceClose] = useState(false);
  const [rejionMeeting, setRejoinMeeting] = useState(null);

  //XPO-FAIRS hook
  const socket = useContext(SocketContext);
  const personRole = getItem("personRole");
  const ParticipantDisplayName = getItem("ParticipantDisplayName");
  const meetingResume = getItem("meetingResume");
  const verifiedResponse = getItem("verifiedResponse");

  const meetingStatus = getItem("meetingStatus");

  const isMobile = window.matchMedia(
    "only screen and (max-width: 768px)"
  ).matches;
  useEffect(() => {
    if (isMobile) {
      window.onbeforeunload = () => {
        return "Are you sure you want to exit?";
      };
    }
  }, [isMobile]);
  useEffect(() => {
    if (meetingResume) {
      if (meetingStatus !== "closed") {
        let payload = {
          meetingId: verifiedResponse.meetingId,
          creatorId: verifiedResponse.creatorId,
          role: USER_TYPES.VISITOR,
          userId: verifiedResponse?.creatorId,
        };
        socket.emit("endScheduledMeeting", payload);
      } else {
        setMeetingStarted(true);
        setToken(meetingResume.token);
        setMeetingId(meetingResume.id);
      }
    }
  }, []);
  console.log("");
  const handleMeetingResumne = (val) => {
    setRejoinMeeting(val);
  };

  useEffect(() => {
    if (meetingStatus === "closed" && meetingResume) {
      setIsMeetingLeft(true);
    }
  }, []);
  console.log("🚀 ~ file: App.js:42 ~ App ~ meetingStaus:", meetingStatus);
  console.log("🚀 ~ file: App.js:42 ~ App ~ isMeetingLeft:", isMeetingLeft);
  console.log("🚀 ~ file: App.js:42 ~ App ~ meetingResume:", meetingResume);

  return (
    <>
      {isMeetingStarted ? (
        <MeetingAppProvider
          selectedMic={selectedMic}
          selectedWebcam={selectedWebcam}
          initialMicOn={micOn}
          initialWebcamOn={webcamOn}
        >
          <MeetingProvider
            config={{
              ProfilePicture: "kdfjghg",
              meetingId,
              micEnabled: micOn,
              webcamEnabled: webcamOn,
              name: ParticipantDisplayName
                ? ParticipantDisplayName
                : "TestUser",
              mode: meetingMode,
              multiStream: true,
            }}
            profile=""
            token={token}
            reinitialiseMeetingOnConfigChange={true}
            joinWithoutUserInteraction={true}
          >
            <MeetingContainer
              setForceClose={forceClose}
              onMeetingLeave={() => {
                // setToken("");
                // setMeetingId(""); If visitor leave meeting then we dont have to remove token and id  , if exhibitor close meeting then we have to remove token and id
                setParticipantName("");
                setWebcamOn(false);
                setMicOn(false);
                setMeetingStarted(false);
              }}
              setIsMeetingLeft={setIsMeetingLeft}
              selectedMic={selectedMic}
              selectedWebcam={selectedWebcam}
              selectWebcamDeviceId={selectWebcamDeviceId}
              setSelectWebcamDeviceId={setSelectWebcamDeviceId}
              selectMicDeviceId={selectMicDeviceId}
              setSelectMicDeviceId={setSelectMicDeviceId}
              micEnabled={micOn}
              webcamEnabled={webcamOn}
            />
          </MeetingProvider>
        </MeetingAppProvider>
      ) : isMeetingLeft ? (
        <LeaveScreen
          displayName={participantName ? participantName : "TestUser"}
          setIsMeetingLeft={setIsMeetingLeft}
          token={token}
          meetingId={meetingId}
          setMeetingStarted={setMeetingStarted}
        />
      ) : (
        <JoiningScreen
          meetingResume={(val) => {
            handleMeetingResumne(val);
          }}
          participantName={participantName}
          setParticipantName={setParticipantName}
          setMeetingId={setMeetingId}
          setToken={setToken}
          setMicOn={setMicOn}
          micEnabled={micOn}
          webcamEnabled={webcamOn}
          setSelectedMic={setSelectedMic}
          setSelectedWebcam={setSelectedWebcam}
          setWebcamOn={setWebcamOn}
          onClickStartMeeting={(token, id) => {
            if (personRole === USER_TYPES.EXHIBITOR) {
              setToken(token);
              setMeetingId(id);
            }
            setMeetingStarted(true);
          }}
          startMeeting={isMeetingStarted}
          setIsMeetingLeft={setIsMeetingLeft}
          meetingMode={meetingMode}
          setMeetingMode={setMeetingMode}
        />
      )}
    </>
  );
};

export default App;
