import { useMeeting } from "@videosdk.live/react-sdk";
import React, { useContext, useEffect, useState } from "react";
import { useMeetingAppContext } from "../MeetingAppContextDef";
import { ParticipantView } from "./ParticipantView";
import { Col, Row, Modal, Button } from "antd";
import { getItem, removeItem, setItem } from "../utils/localStorageControl";
import { USER_TYPES } from "../config/api";
import { SocketContext } from "../socket/socketContext";

const MemoizedParticipant = React.memo(
  ParticipantView,
  (prevProps, nextProps) => {
    return prevProps.participantId === nextProps.participantId;
  }
);

function ParticipantGrid({ participantIds, isPresenting, forceClose }) {
  const [fullView, setFullView] = useState(0);
  const { sideBarMode } = useMeetingAppContext();
  const [searchTimeout, setSearchTimeout] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState(
    "Exhibitor Has Left Meeting Unexpectedly. You Will Get New Link Soon to Rejoin."
  );

  const socket = useContext(SocketContext);
  const personRole = getItem("personRole");
  const verifiedResponse = getItem("verifiedResponse");
  const mMeeting = useMeeting();

  useEffect(() => {
    clearTimeout(searchTimeout);
    setSearchTimeout(
      setTimeout(() => {
        if (mMeeting.participants.size === 1) {
          if (personRole === USER_TYPES.VISITOR) {
            setOpen(true);
          }
          if (mMeeting.participants.size === 1) {
            if (personRole === USER_TYPES.EXHIBITOR) {
              let payload = {
                meetingId: verifiedResponse.meetingId,
                creatorId: verifiedResponse.creatorId,
                role: USER_TYPES.VISITOR,
                userId: verifiedResponse?.creatorId,
                status: "pending",
              };
              socket.emit("endScheduledMeeting", payload);
              console.log("ksjdgfkajgfgjkvsdjkjlkasdbhfgjksv,g");
            }
          }
        }
      }, 10000)
    );
  }, [mMeeting]);

  const isMobile = window.matchMedia(
    "only screen and (max-width: 768px)"
  ).matches;
  console.log(
    "🚀 ~ file: ParticipantGrid.js:48 ~ ParticipantGrid ~ isMobile:",
    isMobile
  );

  const perRow =
    isMobile || isPresenting
      ? participantIds.length < 4
        ? 1
        : participantIds.length < 9
        ? 2
        : 3
      : participantIds.length < 5
      ? 2
      : participantIds.length < 7
      ? 3
      : participantIds.length < 9
      ? 4
      : participantIds.length < 10
      ? 3
      : participantIds.length < 11
      ? 4
      : 4;

  const changeView = () => {
    if (fullView === 0) {
      setFullView(1);
    }
    if (fullView === 1) {
      setFullView(0);
    }
  };
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setConfirmLoading(true);
    let payload = {
      meetingId: verifiedResponse.meetingId,
      creatorId: verifiedResponse.creatorId,
      role: USER_TYPES.VISITOR,
      userId: verifiedResponse?.creatorId,
      status: "pending",
    };
    socket.emit("endScheduledMeeting", payload);

    setItem("meetingStatus", "closed");
    // forceClose(true);
    // removeItem("meetingResume");
    window.location.reload();
  };
  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  return (
    <div
      className={`flex flex-col md:flex-row flex-grow m-3 items-center justify-center ${
        participantIds.length < 2 && !sideBarMode && !isPresenting
          ? "md:px-16 md:py-2"
          : participantIds.length < 3 && !sideBarMode && !isPresenting
          ? "md:px-16 md:py-8"
          : participantIds.length < 4 && !sideBarMode && !isPresenting
          ? "md:px-16 md:py-4"
          : participantIds.length > 4 && !sideBarMode && !isPresenting
          ? "md:px-14"
          : "md:px-0"
      }`}
    >
      {isMobile ? (
        <>
          <div className="flex flex-col w-full h-full">
            {Array.from(
              { length: Math.ceil(participantIds.length / perRow) },
              (_, i) => {
                // console.log(
                //   "participantIds",
                //   Math.ceil(participantIds.length / perRow)
                // );isPresenting
                return (
                  // <Row
                  //   style={{ position: "relative" }}
                  //   gutter={[20, 20]}
                  //   key={`participant-${i}`}
                  //   className={`flex flex-1 ${
                  //     isPresenting
                  //       ? participantIds.length === 1
                  //         ? "justify-start items-start"
                  //         : "items-center justify-center"
                  //       : "items-center justify-center"
                  //   }`}
                  // >
                  <>
                    {participantIds
                      .slice(i * perRow, (i + 1) * perRow)
                      .map((participantId, index) => {
                        return (
                          // <Col
                          //   key={`participant_${participantId}`}
                          //   className={
                          //     participantIds.length !== 1 && fullView === index
                          //       ? "my_View"
                          //       : "participant_view"
                          //   }
                          //   onClick={() => {
                          //     changeView();
                          //   }}
                          // >
                          //   <MemoizedParticipant participantId={participantId} />
                          // </Col>
                          <div
                            key={`participant_${participantId}`}
                            className={`flex flex-1 ${
                              isPresenting
                                ? participantIds.length === 1
                                  ? "md:h-48 md:w-44 xl:w-52 xl:h-48 "
                                  : participantIds.length === 2
                                  ? "md:w-44 xl:w-56"
                                  : "md:w-44 xl:w-48"
                                : "w-full"
                            } items-center justify-center h-full ${
                              participantIds.length === 1
                                ? "md:max-w-7xl 2xl:max-w-[1480px] "
                                : "md:max-w-lg 2xl:max-w-2xl"
                            } overflow-clip overflow-hidden  p-1`}
                          >
                            <MemoizedParticipant
                              participantId={participantId}
                            />
                          </div>
                        );
                      })}
                  </>
                  // </Row>
                );
              }
            )}
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col w-full h-full">
            {Array.from(
              { length: Math.ceil(participantIds.length / perRow) },
              (_, i) => {
                // console.log(
                //   "participantIds",
                //   Math.ceil(participantIds.length / perRow)
                // );isPresenting
                return (
                  <Row
                    style={{ position: "relative" }}
                    gutter={[20, 20]}
                    key={`participant-${i}`}
                    className={`flex flex-1 ${
                      isPresenting
                        ? participantIds.length === 1
                          ? "justify-start items-start"
                          : "items-center justify-center"
                        : "items-center justify-center"
                    }`}
                  >
                    {participantIds
                      .slice(i * perRow, (i + 1) * perRow)
                      .map((participantId, index) => {
                        return (
                          <Col
                            key={`participant_${participantId}`}
                            className={
                              participantIds.length !== 1 && fullView === index
                                ? "my_View"
                                : "participant_view"
                            }
                            onClick={() => {
                              changeView();
                            }}
                          >
                            <MemoizedParticipant
                              participantId={participantId}
                            />
                          </Col>
                          // <div
                          //   key={`participant_${participantId}`}
                          //   className={`flex flex-1 ${
                          //     isPresenting
                          //       ? participantIds.length === 1
                          //         ? "md:h-48 md:w-44 xl:w-52 xl:h-48 "
                          //         : participantIds.length === 2
                          //         ? "md:w-44 xl:w-56"
                          //         : "md:w-44 xl:w-48"
                          //       : "w-full"
                          //   } items-center justify-center h-full ${
                          //     participantIds.length === 1
                          //       ? "md:max-w-7xl 2xl:max-w-[1480px] "
                          //       : "md:max-w-lg 2xl:max-w-2xl"
                          //   } overflow-clip overflow-hidden  p-1`}
                          // >
                          //   <MemoizedParticipant participantId={participantId} />
                          // </div>
                        );
                      })}
                  </Row>
                );
              }
            )}
          </div>
        </>
      )}

      <Modal
        title=""
        footer={false}
        closable={false}
        maskClosable={false}
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <div>
          <p style={{ width: "400px", textAlign: "left", fontSize: "20px" }}>
            {modalText}
          </p>
          <div style={{ textAlign: "right" }}>
            <Button onClick={handleOk} type="primary">
              Ok
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export const MemoizedParticipantGrid = React.memo(
  ParticipantGrid,
  (prevProps, nextProps) => {
    return (
      JSON.stringify(prevProps.participantIds) ===
        JSON.stringify(nextProps.participantIds) &&
      prevProps.isPresenting === nextProps.isPresenting &&
      prevProps.forceClose === nextProps.forceClose
    );
  }
);
